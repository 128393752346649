import userService from "../../services/userService"
import store from "../../store"

import constants from "@/constants"
const WITHOUT_ERROR = constants.WITHOUT_ERROR_CODE

export default {
  setAuthData({ commit }, { authData }) {
    commit("setAuthData", authData)
    localStorage.setItem("user_token", authData.token)
    localStorage.setItem("user_guid", authData.user_guid)
  },

  logout({ commit }) {
    commit("logout")
  },
  updateUserStatus({ commit }) {
    const user_guid = store.getters["user/userGuid"]
    userService
      .getClientID(user_guid)
      .then((resp) => {
        store.commit("balance/updateClientBalance", resp.data)
      })
      .catch((err) => console.log(err))
  },
  async fetchProfileData({ commit }) {
    const user_guid = store.getters["user/userGuid"]
    if (!store.getters["user/clientID"]) {
      try {
        const clientResponse = await userService.getClientID(user_guid)
        const clientId = clientResponse.data.client_id
        const clientName = clientResponse.data.client_name
        if (clientResponse.data.error !== WITHOUT_ERROR) {
          return clientResponse.data.error
        }
        commit("setClientID", clientId)
        commit("setFullName", clientName)
        store.commit("balance/updateClientBalance", clientResponse.data)
      } catch (error) {
        return error
      }
    }
    if (!store.getters["user/companyID"]) {
      try {
        const companyResponse = await userService.getCompanyID()
        const companyId = companyResponse.data.company_id
        if (companyResponse.data.error) {
          return companyResponse.data.error
        }
        commit("setCompanyID", companyId)
      } catch (error) {
        return error
      }
    }
    if (!store.getters["user/clientState"]) {
      try {
        const companyId = store.getters["user/companyID"]
        const clientId = store.getters["user/clientID"]
        const clientStateResponse = await userService.isUserActive(
          companyId,
          clientId
        )
        commit("setClientState", clientStateResponse.data.client_state)
      } catch (error) {
        return error
      }
      return WITHOUT_ERROR
    }
  },
  setEmailVerified({ commit }, { value }) {
    commit("setEmailVerified", value)
  },
  setEmail({ commit }, { email }) {
    commit("setEmail", email)
  },
  async getClientServices({ commit }) {
    // call to api from here
    const company_id = store.getters["user/companyID"]
    const client_id = store.getters["user/clientID"]
    try {
      const services = await userService.getClientServices(
        company_id,
        client_id
      )
      commit("setClientServices", services.data.services)
    } catch (err) {
      console.log(err)
    }
  },
  async getClientBalanceByService({ commit }, service_id) {
    // call to api from here
    const company_id = store.getters["user/companyID"]
    const client_id = store.getters["user/clientID"]
    try {
      const resp = await userService.getClientBalanceByService(
        company_id,
        client_id,
        service_id
      )
      if (resp.data.error !== 0) {
        throw resp.data.messages
      }
      commit("setBalances", resp.data.balances)
      return
    } catch (err) {
      throw new Error(err)
    }
  },
  setOrigin({ commit }, origin){
    commit("setOrigin", origin);
  },
  setMainColor({ commit }, origin){
    commit("setMainColor", origin);
  }
}
