import Vue from "vue"
import Vuex from "vuex"
import user from "@/store/user"
import notifications from "@/store/notifications"
// import global from "@/store/global"
// import balance from "@/store/balance"
// import giftcards from "@/store/giftcards"
// import deposits from "@/store/deposits"
// import debit_cards from "@/store/debit_card"

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    user,
    notifications,
    // global,
    // balance,
    // giftcards,
    // deposits,
    // debit_cards
  },
})

export default store
