import store from "@/store"
import constants from "@/constants"

export default function userLoggedMiddleware(to, from, next) {
  const origin = store.getters["user/getOrigin"];
  if (origin === constants.ORIGIN_SIX_CLOVERS) {
    // console.log(origin, "me voy a six-clovers")
    next({path: "/six-clovers"})
  } else if(origin === constants.ORIGIN_DIETRICH) {
    // console.log(origin, "me voy a Dietrich")
    next({
      path: "/dietrich"
    })
  } else if(origin === constants.ORIGIN_MASLOW) {
    // console.log(origin, "me voy a Dietrich")
    next({
      path: "/maslow"
    })
  } else {
    // console.log(origin, "me voy a ningun lado")
  }

}
