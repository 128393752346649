// import userLoggedMiddleware from "./middleware/userLogged"
import userOriginMiddleware from "./middleware/userOriginMiddleware"
import sixCloverMiddleware from "./middleware/sixCloverMiddleware"
import maslowMiddleware from "./middleware/maslowMiddleware"
import userWithOrigin from "./middleware/userWithOrigin"
// import validateClientIsActive from "./middleware/validateClientIsActive"
// import multiguard from "vue-router-multiguard"

export default [
  // {
  //   path: "/",
  //   name: "Main",
  //   component: () => import("../views/auth/Main.vue")
  // },
  {
    path: "/dietrich",
    component: () => import("@/views/testview/dietrich/new-main.vue"),
    beforeEnter: userOriginMiddleware,
    children: [
      {
        path: "/",
        // redirect: "signup"
        name: "main-dietrich",
        component: () => import("@/views/testview/dietrich/Main.vue")
      },
      {
        path: "signup",
        name: "signup-dietrich",
        component: () => import("../components/onboarding/new-main.vue")
      },
      {
        path: "ValidateToken",
        name: "ValidateToken-dietrich",
        component: () => import("../views/auth/onboarding/ValidateToken.vue")
      },
      {
        path: "ForgotPassword",
        name: "ForgotPassword-dietrich",
        component: () => import("../views/auth/ForgotPassword.vue")
      },
      {
        path: "RecoverPassword",
        name: "RecoverPassword-dietrich",
        component: () => import("../views/auth/RecoverPassword.vue")
      },
      // {
      //   path: "FirstLogin",
      //   name: "FirstLogin-dietrich",
      //   component: () => import("../views/auth/FirstLogin.vue")
      // },
      {
        path: "Activate",
        name: "Activate-dietrich",
        component: () => import("../views/auth/onboarding/Activate.vue")
      },
      {
        path: "*",
        beforeEnter: userWithOrigin
        // name: "main-six-clovers",
        // component: () => import("@/views/testview/six-clover/Main.vue"),
      },
    ]
  },
  {
    path: "/six-clover",
    component: () => import("@/views/testview/six-clover/new-main.vue"),
    beforeEnter: sixCloverMiddleware,
    children: [
      {
        path: "/",
        name: "main-sixclover",
        redirect: "signup"
      },
      {
        path: "signup",
        name: "signup-sixclover",
        component: () => import("@/components/onboarding/six-clovers-main.vue")
      },
      {
        path: "ValidateToken",
        name: "ValidateToken",
        component: () => import("../views/auth/onboarding/ValidateToken.vue")
      },
      {
        path: "ForgotPassword",
        name: "ForgotPassword",
        component: () => import("../views/auth/ForgotPassword.vue")
      },
      {
        path: "RecoverPassword",
        name: "RecoverPassword",
        component: () => import("../views/auth/RecoverPassword.vue")
      },
      // {
      //   path: "FirstLogin",
      //   name: "FirstLogin",
      //   component: () => import("../views/auth/FirstLogin.vue")
      // },
      {
        path: "Activate",
        name: "Activate",
        component: () => import("../views/auth/onboarding/Activate.vue")
      },
      {
        path: "*",
        beforeEnter: userWithOrigin,
        redirect: "signup"
      },
    ]
  },
  {
    path: "/maslow",
    component: () => import("@/views/layout/maslow/starting-view.vue"),
    beforeEnter: maslowMiddleware,
    children: [
      {
        path: "/",
        name: "main-maslow",
        redirect: "signup"
      },
      {
        path: "signup",
        name: "signup-maslow",
        component: () => import("@/components/onboarding/maslow-main.vue")
        // src\components\onboarding\maslow\main.vue
      },
      {
        path: "ValidateToken",
        name: "ValidateToken-maslow",
        component: () => import("../views/auth/onboarding/ValidateToken.vue")
      },
      // {
      //   path: "ForgotPassword",
      //   name: "ForgotPassword-maslow",
      //   component: () => import("../views/auth/ForgotPassword.vue")
      // },
      // {
      //   path: "RecoverPassword",
      //   name: "RecoverPassword-maslow",
      //   component: () => import("../views/auth/RecoverPassword.vue")
      // },
      // {
      //   path: "Activate",
      //   name: "Activate",
      //   component: () => import("../views/auth/onboarding/Activate.vue")
      // },
      {
        path: "*",
        beforeEnter: userWithOrigin,
        redirect: "signup"
      },
    ]
  },
]
