import Vue from "vue"
import {
  LayoutPlugin,
  ModalPlugin,
  CardPlugin,
  TablePlugin,
  ButtonPlugin,
  ImagePlugin,
  AlertPlugin,
  SpinnerPlugin,
  SidebarPlugin,
  FormGroupPlugin,
  PaginationPlugin,
  ListGroupPlugin,
  FormFilePlugin,
  FormSelectPlugin,
  LinkPlugin,
  TabsPlugin,
  FormInputPlugin,
  VBTooltipPlugin,
  FormPlugin,
  InputGroupPlugin,
  TooltipPlugin,
  FormCheckboxPlugin,
} from "bootstrap-vue"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
;[
  LayoutPlugin,
  ModalPlugin,
  CardPlugin,
  TablePlugin,
  ButtonPlugin,
  ImagePlugin,
  AlertPlugin,
  SpinnerPlugin,
  SidebarPlugin,
  FormGroupPlugin,
  PaginationPlugin,
  ListGroupPlugin,
  FormFilePlugin,
  FormSelectPlugin,
  LinkPlugin,
  TabsPlugin,
  FormInputPlugin,
  VBTooltipPlugin,
  FormPlugin,
  InputGroupPlugin,
  TooltipPlugin,
  FormCheckboxPlugin,
].forEach((comp) => {
  Vue.use(comp)
})
