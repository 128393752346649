import actions from "./actions"
import mutations from "./mutations"
import state from "./state"
import getters from "./getters"
/** User store
 * @description this module contain a basic format of user profile
 * with this corresponding data, getters and setters.
 */

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
