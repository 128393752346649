<template>
  <b-alert
    id="notification"
    :style="cssProperties"
    :show="dismissCountDown"
    dismissible
    :variant="variant"
    @dismissed="hideAlert"
  >
    <p>{{ message }}</p>
  </b-alert>
</template>

<script>
export default {
  name: "NotifyAlert",
  props: ["width"],
  computed: {
    cssProperties: function () {
      return `width: ${this.width}%;position: fixed; z-index:1999;margin-left:3%; text-align: center;align-self: center;font-weight: bold;font-size: smaller;height: 59px;`
    },
    dismissCountDown: function () {
      return this.$store.getters["notifications/countdown"]
    },
    message: function () {
      return this.$store.getters["notifications/message"]
    },
    variant: function () {
      return this.$store.getters["notifications/variant"]
    },
  },

  data() {
    return {
      dismissSecs: 5,
      showDismissibleAlert: false,
    }
  },

  methods: {
    hideAlert() {
      this.$store.dispatch("notifications/hideAlert")
    },
  },
}
</script>
