export default {
  logout: (state) => {
    state.auth.loggedIn = false
    state.auth.token = ""
    state.auth.userGuid = ""
    Object.keys(state.profile).forEach((key) => {
      state.profile[key] = ""
    })
    localStorage.removeItem("user_token")
    localStorage.removeItem("user_guid")
    sessionStorage.removeItem("actual_service")
  },
  setAuthData: (state, authData) => {
    state.auth.token = authData.token
    state.auth.userGuid = authData.user_guid
    state.auth.loggedIn = true
  },
  setClientState: (state, clientState) => {
    state.auth.clientState = clientState
  },
  setClientID: (state, clientID) => {
    state.profile.clientID = clientID
  },
  setCompanyID: (state, companyID) => {
    state.profile.companyID = companyID
  },
  setEmailVerified: (state, value) => {
    state.profile.emailVerified = value
  },
  setEmail: (state, email) => {
    state.profile.email = email
  },
  setFullName: (state, fullName) => {
    state.profile.fullName = fullName
  },
  setBalances: (state, balances) => {
    state.balances = balances
  },
  setClientServices: (state, services) => {
    state.services = services
  },
  setOrigin: (state, origin) => {
    state.origin = origin
  },
  setMainColor: (state, color) => {
    state.mainColor = color
  },
}
