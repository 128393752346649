import http from "./baseApi"
const GXOBJECT_API = process.env.VUE_APP_GXOBJECT_API

export default {
  getClientServices(company_id, client_id) {
    return http.post("/ws/services_by_client", {
      company_id,
      client_id
    })
  },

  getClientID(userId) {
    return http.post("/customer/client_by_user_id", {
      UserId: userId.trim()
    })
  },
  getCompanyID() {
    return http.post("/ws/def_company")
  },
  changePasswordLoggedIn(currentPassword, newPassword1, newPassword2) {
    return http.post("/customer/change_pass", {
      UserPassword: currentPassword,
      UserPasswordNew: newPassword1,
      UserPasswordNewConf: newPassword2
    })
  },
  changePasswordFirsLogin(userEmail, newPassword1, newPassword2) {
    return http.post("/customer/change_pass_first", {
      Email: userEmail,
      GAMPassword: newPassword1,
      GAMPassword_conf: newPassword2
    })
  },
  updateClientPassword() {
    return http.post("/customer/change_pass", {
      Email: "string",
      ApplicationClientId: 0,
      UserPassword: "string",
      UserPasswordNew: "string",
      UserPasswordNewConf: "string"
    })
  },
  recoverPassword(KeyToChangePassword, Email, GAMPassword, GAMPassword_conf) {
    return http.post("/customer/new_pass", {
      KeyToChangePassword: KeyToChangePassword,
      Email: Email,
      GAMPassword: GAMPassword,
      GAMPassword_conf: GAMPassword_conf
    })
  },
  signUp(
    dni,
    email,
    fullName,
    password1,
    password2,
    phoneNumber,
    client_ori,
    client_act,
    client_type,
    documents,
    client_doc_data,
    politically_exposed
  ) {
    const payload = {
      EMail: email,
      Password: password1,
      PasswordConf: password2,
      client_name: fullName,
      client_cuit: dni,
      client_phone: phoneNumber,
      // doc_frente_dni: documents[0].client_doc, // cambiar por imagen por defecto
      // type_frente_dni: documents[0].client_doc_type,
      // doc_dorso_dni: "documents[0].client_doc",
      // type_dorso_dni: "jpeg",
      // doc_selfie: "documents[0].client_doc",
      // type_selfie: "jpeg",
      client_ori,
      // client_act,
      client_type,
      documents,
      client_doc_data: JSON.stringify(client_doc_data),
      politically_exposed
    }

    if(client_act){
      payload.client_act = client_act
    }

    const formData = new FormData()

    for (const [key, value] of Object.entries(payload)) {
      if(key !== "documents" && key !== "client_doc_data"){
        formData.append(key, value)
      } else if(key === "client_doc_data"){
        for (const [k, v] of Object.entries(value)) {
          formData.append(`${key}[${k}]`, v)          
        }
      } else if(key === "documents"){
        for (const doc of value) {
          for (const [k, v] of Object.entries(doc)) {
            formData.append(`${key}[${k}]`, v)          
          }
        }
      }
    }

    const config = {
      onUploadProgress: progressEvent => console.log(progressEvent.loaded)
    }
    return http.post("/customer/user_insert", payload, config)
  },
  signUpMaslow(
    newClient
  ) {
    const payload = {
      client_ori: newClient.client_ori,
      email: newClient.email,
      password: newClient.password,
      password_conf: newClient.password_conf,
      first_name: newClient.first_name,
      surname: newClient.surname,
      client_cuit: newClient.client_cuit,
      client_phone: newClient.client_phone,
      client_bir_dat: newClient.client_bir_dat,
      client_gender: newClient.client_gender,
      client_cbu: newClient.client_cbu,
      politically_exposed: newClient.politically_exposed,
      client_add_str: newClient.client_add_str,
      client_add_num: newClient.client_add_num,
      client_add_floor: newClient.client_add_floor,
      client_add_atp: newClient.client_add_atp,
      client_add_city: newClient.client_add_city,
      client_add_pc: newClient.client_add_pc,
      province_id: newClient.province_id,
      // province_name: newClient.province_name,
      country_id: newClient.country_id,
      sixclovers_nat: newClient.sixclovers_nat,
      sixclovers_country: newClient.sixclovers_country,
      doc_frente_dni: newClient.doc_frente_dni,
      type_frente_dni: newClient.type_frente_dni,
      doc_dorso_dni: newClient.doc_dorso_dni,
      type_dorso_dni: newClient.type_dorso_dni,
      doc_selfie: newClient.doc_selfie,
      type_selfie: newClient.type_selfie
    }
    return http.post("/user_insert_maslow", payload)
  },
  sixCloversSignUp(
    newClient
  ) {
    const payload = {
      client_ori: newClient.client_ori,
      email: newClient.email,
      password: newClient.password,
      password_conf: newClient.password_conf,
      first_name: newClient.first_name,
      surname: newClient.surname,
      client_cuit: newClient.client_cuit,
      client_phone: newClient.client_phone,
      client_bir_dat: newClient.client_bir_dat,
      client_gender: newClient.client_gender,
      client_cbu: newClient.client_cbu,
      politically_exposed: newClient.politically_exposed,
      client_add_str: newClient.client_add_str,
      client_add_num: newClient.client_add_num,
      client_add_floor: newClient.client_add_floor,
      client_add_atp: newClient.client_add_atp,
      client_add_city: newClient.client_add_city,
      client_add_pc: newClient.client_add_pc,
      province_id: newClient.province_id,
      // province_name: newClient.province_name,
      country_id: newClient.country_id,
      sixclovers_nat: newClient.sixclovers_nat,
      sixclovers_country: newClient.sixclovers_country,
      doc_frente_dni: newClient.doc_frente_dni,
      type_frente_dni: newClient.type_frente_dni,
      doc_dorso_dni: newClient.doc_dorso_dni,
      type_dorso_dni: newClient.type_dorso_dni,
      doc_selfie: newClient.doc_selfie,
      type_selfie: newClient.type_selfie
    }
    return http.post("/customer/user_insert_sixclovers", payload)
  },
  activateUser(EMail, token) {
    return http.post("/customer/user_activate", {
      EMail: EMail,
      token: token
    })
  },
  async uploadPicture(pictureFile, pictureName) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
    const formData = new FormData()
    formData.append("files", pictureFile, `${pictureName}.jpg`)
    try {
      const response = await http.post(GXOBJECT_API, formData, config)
      if (response) {
        return response.data.files.length < 1
          ? new Error(response)
          : response.data.files[0].path
      }
    } catch (error) {
      return new Error(error)
    }
  },
  isUserActive(company_id, client_id) {
    return http.get(`/client_bc/${company_id},${client_id}`)
  },
  ClientTokenRequest(mial, name, last_name) {
    return http.post("/customer/client_token_request", {
      mial,
      name,
      last_name
    })
  },
  ValidateToken(token_num) {
    return http.post("/customer/validate_token", { token_num })
  }
}
