export default {
  auth: {
    loggedIn: false,
    token: localStorage.getItem("user_token") || "",
    userGuid: localStorage.getItem("user_guid") || "",
    clientState: "",
  },
  profile: {
    companyID: "",
    email: "",
    fullName: "",
    clientID: "",
    emailVerified: false,
  },
  // INFO: i call JSON.parse because localstorage only accept string
  services: [],
  origin: null,
  mainColor: ''
}
