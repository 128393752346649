import Vue from "vue"
import routes from "./router/routes"
import Router from "vue-router"

Vue.use(Router)

export default new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes,
})
