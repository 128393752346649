<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <notify-alert width="80" />
    </transition>
    <b-container fluid class="main-container">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </b-container>
  </div>
</template>

<script>
import NotifyAlert from "@/views/auth/NotifyAlert"
// import "@/assets/scss/theme.scss"

export default {
  name: 'App',
  components: {
    NotifyAlert,
  }
}
</script>

<style>
html,
body {
  height: 100%;
}
#app {
  /*font-family: "Avenir", Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-container {
  margin-top: 20px;
  padding: 0;
}

.hidden {
  display: none;
}

@media (max-width: 991px) {
}
</style>
