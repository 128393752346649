// Default library to handler the requests
import axios from "axios"
// Vue Store where the global data is stored
import { store } from "@/store/"
import router from "@/router/"

/** @type {'string'} The app's BASE_API is the url of the backend */
const BASE_API = process.env.VUE_APP_BASE_API
// let BASIC_AUTH_TOKEN = process.env.VUE_APP_BASIC_AUTH_TOKEN;
// let CLIENT_ID = process.env.VUE_APP_CLIENT_ID;
// let OAUTH_URL = process.env.VUE_APP_OAUTH_URL;

/** @type {number} - use to set the max time out of the request */
// const fiveteenSecond = 15000

/** @type {object} Instantiation of the Axios Object
 *  @description - We can modified this object with our specific Headers to implements these in each request that will we make
 * or set some properties like timeout
 */
const http = axios.create({
  baseURL: BASE_API,
  // timeout: 60000,
  // timeout: fiveteenSecond,
})

http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("user_token") || ""
    if (token) {
      config.headers.Authorization = `OAuth ${token}`
    }

    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)
http.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("user/logout")
      
      router.push({
        name: store.user.getters.routeByOrigin
      })
    } else {
      return Promise.reject(error)
    }
  }
)

export default http
