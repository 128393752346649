/** @description this module it's used to throw notification to the user with state of the current process or some user action */

const state = {
  message: "",
  variant: "",
  countdown: 0,
}

const actions = {
  notify({ commit }, { notification }) {
    commit("notify", notification)
  },
  hideAlert({ commit }) {
    commit("hideAlert")
  },
}

const mutations = {
  notify: (state, notification) => {
    state.message = notification.message
    state.variant = notification.variant
    state.countdown = notification.countdown || 0
  },
  hideAlert: (state) => {
    state.countdown = 0
  },
}

const getters = {
  message: (state) => state.message,
  variant: (state) => state.variant,
  countdown: (state) => state.countdown,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
