import constants from "@/constants"

export default {
  isLoggedIn: (state) => state.auth.token !== "",
  clientState: (state) => state.auth.clientState,
  token: (state) => state.auth.token,
  userGuid: (state) => state.auth.userGuid,
  cuil: (state) => state.profile.cuil,
  clientID: (state) => state.profile.clientID,
  companyID: (state) => state.profile.companyID,
  email: (state) => state.profile.email,
  emailVerified: (state) => state.profile.emailVerified,
  fullName: (state) => state.profile.fullName,
  balances: (state) => state.balances,
  getMainColor: (state) => state.mainColor,
  getOrigin: (state) => state.origin,
  shouldReload: (state) => {
    switch(state.origin){
      case constants.ORIGIN_DIETRICH:
        return false;
      case constants.ORIGIN_SIX_CLOVERS:
        return true
      case constants.ORIGIN_MASLOW:
        return true
      default: return false
    }
  },
  routeByOrigin: (state) => {
    switch(state.origin){
      case constants.ORIGIN_DIETRICH:
        return "main-dietrich";
      case constants.ORIGIN_SIX_CLOVERS:
        return "main-sixclover"
      case constants.ORIGIN_MASLOW:
        return "start-maslow"
      default: return "/"
    }
  },
  pathByOrigin: (state) => {
    switch(state.origin){
      case constants.ORIGIN_DIETRICH:
        return "/dietrich";
      case constants.ORIGIN_SIX_CLOVERS:
        return "/six-clover"
      case constants.ORIGIN_MASLOW:
        return "/maslow"
      default: return "/"
    }
  }
}
