import "@/plugins/bootstrap-vue"
import Vue from 'vue'
import Vuelidate from "vuelidate"
import router from "./router"
import { store } from "@/store/"
import titleMixin from '@/mixins/titleMixin'
import App from './App.vue'

Vue.use(Vuelidate)

Vue.config.productionTip = false

Vue.mixin(titleMixin)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
