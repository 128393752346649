export default {
  REQUIRED_ACCION_MESSAGE: "Comunicarse con su ejectutivo de cuenta", // should appear in the second row of the operation preview if the status is pendimg
  BUY_OPERATION_CODE: 1, // Operation code for operation buy types
  SELL_OPERATION_CODE: 2, // Operation code for operation sell types
  DEPOSIT_OPERATION_CODE: 3, // Operation code for operation deposit types
  PENDING_OPERATION_STATE: 2, // Operation's state code when this state is pending
  WITHOUT_ERROR_CODE: 0, // Success response code
  NULL_DATE: "/  /", // Value that backend return when the date's null
  WORKFLOW_FINISHED_STATE: "OK", // each step has two state finished="OK" and OnGoing="OG"
  CHANGE_PASSWORD_GAM_CODE: "23", // this code appear when the user do the first login
  EXPIRED_PASSWORD_GAM_CODE: "24", // this code appear when the user passwrod expire
  DEFAULT_MAIN_VIEW: "/", // this code appear when the user passwrod expire
  CLIENT_STATE_ACTIVE: 2,
  USER_NOT_ACTIVATED_GAM_CODE: "9",
  SERVICE_PRESTAMOS_COD: 3,
  SERVICE_INVERSIONES_COD: 2,
  SLIDER_REDUCTOR_CONSTANT: 5, // esta consstanse se usa para los márgenes de las marcas en el slider.
  TEXT_MANAGER_TERMS_AND_CONDITIONS_CODE: process.env.VUE_APP_TOC_CODE,
  TEXT_MANAGER_POLICY_OF_CONTENTS_CODE: process.env.VUE_APP_PDP_CODE,
  TEXT_MANAGER_USER_NOT_DEBIT_CARD: process.env.VUE_APP_USERNTD_CODE,
  TEXT_MANAGER_DEBIT_CARD_TOC: process.env.VUE_APP_TOCDB_CODE,
  TEXT_MANAGER_DEBIT_CARD_TOC_HELP: process.env.VUE_APP_TOCDBH_CODE,
  TEXT_MANAGER_OK_DEBIT_CARD_TEXT: process.env.VUE_APP_TOCOKM_CODE,
  TEXT_MANAGER_CONFIRM_DEBIT_CARD_TEXT: process.env.VUE_APP_TDCONF_CODE,
  ORIGIN_DIETRICH: 1,
  ORIGIN_SIX_CLOVERS: 2,
  ORIGIN_MASLOW: 3,
  BITHAN_LOGIN: process.env.VUE_APP_BITHAN_LOGIN
}
